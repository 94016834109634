import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Cookies Policy | IrisVR'
const description = 'IrisVR, Inc. Cookies And Tracking Notice'
const url = `${getWindowLocationOrigin()}/cookies-policy`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Prospect2.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
