export const cookies = [
  {
    name: 'Adwords',
    purpose:
      'To measure the success of our Google advertising campaigns by measuring web page visits',
    website: 'www.irisvr.com',
    link: 'https://policies.google.com/technologies/ads',
  },
  {
    name: 'Facebook',
    purpose:
      'To obtain information regarding the activities that users engage in while visiting our web pages',
    website: 'www.irisvr.com, blog.irisvr.com, help.irisvr.com',
    link: 'https://www.facebook.com/about/privacy',
  },
  {
    name: 'Google Analytics',
    purpose:
      'To collect data about website usage which does not include personally identifiable information',
    website: 'www.irisvr.com, blog.irisvr.com, help.irisvr.com',
    link: 'www.google.com/policies/privacy/',
  },
  {
    name: 'Hotjar',
    purpose:
      'To monitor website usage through heatmaps and surveys to help us improve the user experience',
    website: 'www.irisvr.com',
    link: 'https://www.hotjar.com/legal/policies/privacy',
  },
  {
    name: 'Hubspot',
    purpose:
      'To collect data on web page visits for email marketing and webinar campaigns',
    website: 'info.irisvr.com, blog.irisvr.com, www.irisvr.com',
    link: 'https://legal.hubspot.com/privacy-policy',
  },
  {
    name: 'LinkedIn',
    purpose:
      'To obtain information on our website of the activities users engage in to facilitate retargeting',
    website: 'www.irisvr.com',
    link: 'https://www.linkedin.com/legal/privacy-policy',
  },
  {
    name: 'Outbrain',
    purpose:
      'To obtain information regarding the activities that users engage in while visiting our web pages',
    website: 'www.irisvr.com, blog.irisvr.com',
    link: 'https://www.outbrain.com/legal/',
  },
  {
    name: 'Segment',
    purpose:
      'To provide website usage and user data to help us refine onboarding and build new features',
    website: 'www.irisvr.com, blog.irisvr.com, help.irisvr.com',
    link: 'https://segment.com/docs/legal/privacy/',
  },
  {
    name: 'Stripe',
    purpose: 'To monitor customer billing via our pricing and billing pages',
    website: 'www.irisvr.com',
    link: 'https://stripe.com/us/privacy/',
  },
  {
    name: 'Typekit',
    purpose: 'To collect data on which fonts are served and viewed on our blog',
    website: 'blog.irisvr.com',
    link: 'https://www.adobe.com/privacy/policies/typekit.html',
  },
  {
    name: 'Visual Website Optimizer',
    purpose:
      'To test different web configurations by measuring aggregate user engagement',
    website: 'www.irisvr.com',
    link: 'https://vwo.com/privacy-policy/',
  },
  {
    name: 'Wordpress',
    purpose:
      'To obtain information on which blog content is most popular and where traffic is flowing',
    website: 'learn.irisvr.com',
    link: 'https://wordpress.org/about/privacy/',
  },
  {
    name: 'Zendesk',
    purpose:
      'To deliver a chat bar on our website and collect data on pageviews in our knowledge base',
    website: 'help.irisvr.com, www.irisvr.com',
    link: 'https://www.zendesk.com/company/customers-partners/privacy-policy/',
  },
]
