import React from 'react'
import { Helmet } from '../components/Shared'
import CookiePolicy, { helmetProps } from '../components/Policy/Cookie'

export default React.memo(() => (
  <>
    <Helmet {...helmetProps} />
    <CookiePolicy />
  </>
))
