import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../Shared'
import helmetProps from './helmetProps'
import { cookies } from './cookies'
import './CookiesPolicy.global.scss'

const CookiesPolicy = () => (
  <Layout>
    <div id="cookies-policy">
      <div className="shadow-nav" />
      <div className="content-container">
        <div className="intro">
          <p className="main-header">Cookies & Tracking Notice</p>
          <p className="header">Effective Starting Date: February 19, 2021</p>
          <p className="paragraph-text">
            We use cookies, web beacons and other technologies on www.irisvr.com
            and its subdomains to improve and customize our websites and
            products (&#34;the Services&#34;) and your experience. Cookies help
            us understand how people use a website, remember a user’s login
            details, and store website preferences. This page explains how we
            use cookies and other similar technologies to help us ensure that
            our Services function properly and analyze and improve the Services
            in accordance with our <Link to="/privacy">Privacy Policy</Link>. By
            continuing to browse the site, you are agreeing to our use of
            cookies.
          </p>
        </div>
        <div className="cookie-tech">
          <p className="header">
            We use the following cookies and other technologies:
          </p>
          <ol className="paragraph-text">
            <li>
              <span className="emphasis">Strictly necessary cookies</span>.
              These are cookies that are required for the operation of our
              website. They include, for example, cookies that enable you to log
              into our website, use a Product Library page, or make use of
              online billing services.
            </li>
            <li>
              <span className="emphasis">Analytical/performance cookies</span>.
              They allow us to recognize and count the number of visitors and to
              see how visitors move around our website when they are using it.
              This helps us to improve the way our website works, for example,
              by ensuring that users find what they are looking for easily.
            </li>
            <li>
              <span className="emphasis">Functionality cookies</span>. These are
              used to recognize you when you return to our website. This enables
              us to personalize our content for you, greet you by name, and
              remember your account preferences.
            </li>
            <li>
              <span className="emphasis">Targeting cookies</span>. These cookies
              record your visit to our website, the pages you have visited and
              the links you have followed. We will use this information to make
              our website and the advertising displayed on it more relevant to
              your interests. We may also share this information with third
              parties for this purpose.
            </li>
          </ol>
        </div>
        <div className="first-party-tech">
          <p className="header">First-party (IrisVR) tracking technologies</p>
          <p className="paragraph-text">
            Our websites will set local storage data that is essential for the
            website to operate correctly. These are as follows:
          </p>
          <ul className="paragraph-text">
            <li>
              <span className="emphasis">Token</span> – this cookie is a
              URL-safe JSON web token (JWT) designed to enable the single
              sign-on (SSO) web browsing experience. it also verifies the user’s
              access to any personal data they may request from the IrisVR
              servers;
            </li>
            <li>
              <span className="emphasis">User ID</span> – this cookie is a
              numeric value that identifies unique visitors and provides
              coherence and consistency to a site visit;
            </li>
            <li>
              <span className="emphasis">Username</span> – this cookie is a text
              value that identifies unique visitors by user email and provides
              data for email notifications;
            </li>
          </ul>
        </div>
        <div className="third-party-tech">
          <p className="header">Third-party cookies and other technologies</p>
          <p className="paragraph-text">
            We do not control the operation of third-party cookies on our
            website. These include:
          </p>
          <table className="paragraph-text">
            <tr>
              <th>Set by or on behalf of</th>
              <th>Purpose</th>
              <th>Website</th>
              <th>Privacy Notice</th>
            </tr>
            {cookies.map(c => (
              <tr>
                <td>{c.name}</td>
                <td>{c.purpose}</td>
                <td>{c.website}</td>
                <td>
                  <a href={c.link}>{c.link}</a>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="cookie-management">
          <p className="header">How to manage cookies</p>
          <p className="paragraph-text">
            To opt-out of our use of cookies, you can instruct your browser, by
            changing its options, to stop accepting cookies or to prompt you
            before accepting a cookie from websites you visit. Please consult
            the help section of your web browser or follow the links below to
            understand your options, but please note that if you choose to
            disable the cookies, some features of our website or Services may
            not operate as intended.
          </p>
          <ul>
            <li>
              Chrome:{' '}
              <a href="https://support.google.com/chrome/answer/95647?hl=en">
                https://support.google.com/chrome/answer/95647?hl=en
              </a>
            </li>
            <li>
              Explorer:{' '}
              <a href="https://support.microsoft.com/en-us/products/windows?os=windows-10">
                https://support.microsoft.com/en-us/products/windows?os=windows-10
              </a>
            </li>
            <li>
              Safari:{' '}
              <a href="https://support.apple.com/kb/PH21411">
                https://support.apple.com/kb/PH21411
              </a>
            </li>
            <li>
              Firefox:{' '}
              <a href="https://support.mozilla.org/products/firefox/cookies">
                https://support.mozilla.org/products/firefox/cookies
              </a>
            </li>
            <li>
              Opera:{' '}
              <a href="http://www.opera.com/help/tutorials/security/cookies">
                http://www.opera.com/help/tutorials/security/cookies
              </a>
            </li>
          </ul>
        </div>
        <div className="cookie-updates">
          <p className="header">Updates to this Notice</p>
          <p className="paragraph-text">
            This Cookies & Tracking Notice may be updated from time to time. If
            we make any changes, we will notify you and revise the
            &#34;effective starting&#34; date at the top of this notice.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default CookiesPolicy

export { helmetProps }
